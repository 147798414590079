const Nav = ({ nav, site }) => {
  return (
    <nav>
      <div className="nav--left">
        <h1>
          {site.name} {"// "}
          <span className="header--accent">{site.role}</span>
        </h1>
      </div>
      <div className="nav--right">
        <ul>
          {nav.map((item, idx) => {
            if (idx === 0) {
              return (
                <li key={item.name}>
                  <a href={item.slug} rel="noreferrer">
                    {item.name}
                  </a>
                </li>
              );
            } else {
              return (
                <li key={item.name}>
                  <a href={item.slug} target="_blank" rel="noreferrer">
                    {item.name}
                  </a>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
