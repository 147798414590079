import aboutData from "./aboutData";

let terminalData = {
  comp_name: "sparker_dev",
  comp_ext: "SP-Port",
  about: aboutData.blurb,
  options: ["help", "about", "projects", "resume"],
  associatedOptions: {
    help: ["help", "helps"],
    about: ["about", "about stephen", "abouts", "bio"],
    projects: ["project", "projects", "work", "code", "projec", "works"],
  },
  projects: [
    {
      name: "Paraphilia",
      year: 2023,
      url: "https://paraphilia.band",
      github: "https://github.com/stephenparkhum/Paraphilia-2021",
    },
    {
      name: "Maestus",
      year: 2020,
      url: "https://maest.us",
      github: "https://github.com/stephenparkhum/Maestus-2020",
    },
    {
      name: "Glossolalia Records",
      year: 2020,
      url: "https://glossolaliarecords.com",
      github: "https://github.com/stephenparkhum/Glossolalia-Records-V2",
    },
    {
      name: "Contour",
      year: 2019,
      url: "",
      github: "https://github.com/stephenparkhum/Contour",
    },
    {
      name: "genriFy",
      year: 2019,
      url: "https://stephenparkhum.github.io/genriFy/",
      github: "https://github.com/stephenparkhum/genriFy",
    },
  ],
};
export default terminalData;
