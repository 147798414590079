import BaseComponent from "./baseComponent";
const Resume = () => {
  const myData = {
    cv: "https://docs.google.com/document/d/1kU548PbzlqiaZdtcFzvNyUbMRtjQizzy/edit#",
    linkedin: "https://www.linkedin.com/in/stephenparker-03/",
  };
  return (
    <BaseComponent title={"*** Resume ***"}>
      <span className="white">LinkedIn:</span>{" "}
      <a href={myData.linkedin} target="_blank" rel="noreferrer">
        {myData.linkedin}
      </a>
      <br />
      <span className="white">Resume: </span>
      <a href={myData.cv} target="_blank" rel="noreferrer">
        {myData.cv}
      </a>
    </BaseComponent>
  );
};

export default Resume;
