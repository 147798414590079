// Importing CSS styles
import "./App.css";
import "./styles/nav.css";
import "./styles/terminal.css";

// Site Data
import data from "./data/data";

// Components
import Nav from "./components/Nav";
import Terminal from "./components/Terminal";

function App() {
  return (
    <div className="App">
      <header>
        <Nav nav={data.nav} site={data.site} />
      </header>
      <main>
        <Terminal term={data.term} />
        <code className="d-text">
          Hint: type 'help' to see more info || Best viewed on desktop!
          <span className="mobile-warning">
            <br />
            <br />
            NOTE: The mobile version is still in progress!
          </span>
        </code>
      </main>
    </div>
  );
}

export default App;
