import BaseComponent from "./baseComponent";
const TerminalProjects = ({ projects }) => {
  return (
    <>
      <BaseComponent title="*** Projects ***">
        {projects.map((item) => {
          return (
            <>
              <span className="white">
                * {item.name} - ({item.year})
              </span>
              <br />
              <span className="s-text">**</span> Live Site:{" "}
              <a href={item.url}>{item.url}</a>
              <br />
              <span className="d-text" key={item.name}>
                **
              </span>{" "}
              Github: <a href={item.github}>{item.github}</a>
              <br />
              <br />
            </>
          );
        })}
      </BaseComponent>
    </>
  );
};
export default TerminalProjects;
