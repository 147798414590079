let aboutData = {
  header: `Hi, I'm Stephen...`,
  blurb: `
  As a Portland, Oregon-based web developer, I have a passion for creating pixel-perfect experiences that serve every type of user, no matter their ability, interest, or choice of device. I believe that an excellent web developer must take an ‘always-learning’ approach to new technologies and collaborations.
  
  I’m proficient in a variety of programming languages and frameworks, and I’m always up-to-date on the latest trends in web development. I’m also a strong communicator, and I enjoy working with clients to understand their needs and create solutions that meet their goals. 
  
  If you’re looking for a web developer who can create innovative and user-friendly websites, I’d love to chat with you. Please feel free to contact me at stephenparkhum@outlook.com.
  
  I look forward to hearing from you!
`,
};

export default aboutData;
