import siteData from "./siteData";
import navData from "./navData";
import terminalData from "./terminalData";

const data = {
  site: siteData,
  nav: navData,
  term: terminalData,
};

export default data;
