import BaseComponent from "./baseComponent";
const TerminalGit = ({ obj }) => {
  const displayGitData = (object) => {
    const date = new Date(object.created_at);
    return (
      <>
        <BaseComponent title={"*** Github ***"}>
          <span className="white">Username:</span> {object.login}
          <br />
          <span className="white">Location:</span> {object.location}
          <br />
          <span className="white">Company: </span>{" "}
          {object.company ? object.company : "None, currently!"}
          <br />
          <span className="white">URL:</span>{" "}
          <a href={object.html_url} target="_blank" rel="noreferrer">
            {object.html_url}
          </a>
          <br />
          <span className="white">Public Repos:</span> {object.public_repos} (
          <a
            href={`https://github.com/${object.login}?tab=repositories`}
            target="_blank"
            rel="noreferrer"
          >
            View All
          </a>
          )
          <br />
          <span className="white">Public Gists:</span> {object.public_gists}
          <br />
          <span className="white">Looking for work?</span>{" "}
          {!object.company
            ? "Yes! Email stephenparkhum@outlook.com to get in touch!"
            : "Not currently looking, but stay in touch!"}
          <br />
          <span className="white">Followers: </span>
          {object.followers} (
          <a target="_blank" href={object.followers_url} rel="noreferrer">
            View All
          </a>
          )
          <br />
          <span className="white">Member Since:</span> {date.getFullYear()}
        </BaseComponent>
      </>
    );
  };
  return <>{displayGitData(obj)}</>;
};

export default TerminalGit;
