import BaseComponent from "./baseComponent";

const Help = () => {
  return (
    <BaseComponent title={"*** Need Help? ***"}>
      type 'cd projects' to change into the projects dir and display projects
      <br />
      <br />
      ** Available Options ** <br />
      <br />* about
      <br />* project(s)
      <br />* git (or github)
      <br />* help
      <p>When in doubt shoot me an email at stephenparkhum@outlook.com</p>
    </BaseComponent>
  );
};

export default Help;
