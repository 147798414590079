import React, { useState, useEffect } from "react";
import TerminalProjects from "./TerminalProjects";
import About from "./About";
import Help from "./Help";
import Resume from "./Resume";
import TerminalGit from "./TerminalGit";
import terminalData from "../data/terminalData";

const Terminal = ({ term }) => {
  const [codeState, setCodeState] = useState("");
  const [currentPath, setCurrentPath] = useState("");
  const [showProjects, setShowProjects] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showGit, setShowGit] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const [myGit, setMyGit] = useState({});

  const { associatedOptions } = terminalData;

  useEffect(() => {
    fetch("https://api.github.com/users/stephenparkhum")
      .then((res) => res.json())
      .then((result) => {
        setMyGit(result);
      });
  }, []);

  const changeCodeState = (e) => {
    setCodeState(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let val = e.target[0].value.toLowerCase();

    associatedOptions.projects.includes(val)
      ? setShowProjects(true)
      : setShowProjects(false);
    val.includes("about") ? setShowAbout(true) : setShowAbout(false);
    val.includes("git") ? setShowGit(true) : setShowGit(false);
    val.includes("help") ? setShowHelp(true) : setShowHelp(false);
    val.includes("resume") ? setShowResume(true) : setShowResume(false);

    // This resets the input to be blank
    setCodeState("");

    // This sets the current path
    setCurrentPath(val);
  };

  return (
    <div className="terminal--container">
      <div className="terminal--subcontainer">
        <code className="p-text">
          {`${term.comp_name}@${term.comp_ext}/${currentPath} | `}
          <span className="s-text">$</span>
        </code>
        <form onSubmit={onSubmit}>
          <input
            type="text"
            className="code--input s-text"
            name="codeInput"
            id="codeInput"
            autoFocus="autofocus"
            value={codeState}
            onChange={changeCodeState}
          />
          <input type="submit" value="Submit" hidden />
        </form>
      </div>
      <code className="terminal--code">
        {showProjects ? <TerminalProjects projects={term.projects} /> : ""}
        {showAbout ? <About about={term.about} /> : ""}
        {showHelp ? <Help /> : ""}
        {showResume ? <Resume /> : ""}
        {showGit ? <TerminalGit obj={myGit} /> : ""}
      </code>
    </div>
  );
};

export default Terminal;
