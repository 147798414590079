let navData = [
  {
    name: "home",
    slug: "/",
  },
  {
    name: "github",
    slug: "https://github.com/stephenparkhum",
  },
  {
    name: "linkedin",
    slug: "https://www.linkedin.com/in/stephenparker-03/",
  },
  {
    name: "resume",
    slug: "https://docs.google.com/document/d/15Xg996E06rgfkLnsSE6xp3XMlqO7dzZPGuMvBWntQX8/edit",
  },
  {
    name: "contact",
    slug: "mailto:stephenparkhum@outlook.com",
  },
];
export default navData;
