const BaseComponent = (props) => {
  return (
    <div className="base-component">
      <h3>{props.title}</h3>
      <p>{props.content ? props.content : props.children}</p>
    </div>
  );
};

export default BaseComponent;
